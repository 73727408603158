//设置cookie
export function setCookies(obj, limitTime,domain = 'zzt.com.cn') {
    let data = new Date(new Date().getTime() + limitTime * 24 * 60 * 60 * 1000).toUTCString()

    for (let i in obj) {
        document.cookie = i + '=' + obj[i] + ';expires=' + data  + ';path=/;domain=.' + domain
    }
}

//查看
// 该函数只有一个参数，即我们需要查询的cookie名称，然后返回一个值
// let cookieValue = searchCookie(name)
export function searchCookie(cookieName) {
    let re = new RegExp("\s?" + cookieName + "=([^;]+)(;|$)")
    return document.cookie.match(re)[1]
}

//删除cookie
//该函数只有一个参数，传入一个数组，每个元素为我们想要删除的cookie名
// removeCookies(['name', 'age'])
export function removeCookies(cookieList, domain = 'zzt.com.cn') {
    let data = new Date(new Date().getTime() - 24*60*60*1000).toUTCString()
    for(let i in cookieList) {
        document.cookie = cookieList[i] + '= ;' + 'expires=' + data + ';path=/;domain=.' + domain
    }
}