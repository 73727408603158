import axios from 'axios'
import store from '@/store'
import vue from "vue";
import router from "@/router";

axios.defaults.withCredentials = true; //设置默认请求带上cookie
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL
  // baseURL: 'http://test-www.zzt.com.cn/api/'
})

// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    const token = store.state.token
    if (token) {
      config.headers.token = token
    }
    return config
  },
  (error) => {
    const { code, message, response } = error
    console.log(code, message)
    return Promise.reject(response.status) // 返回接口返回的错误信息
  })

request.interceptors.response.use(
  (response) => {
    const { data, config } = response
    if (data?.code === '0001' && config.url !== '/eams/outbound/sys-users/ifValidToken') {
      vue.prototype.$message.error(data.message)
      store.dispatch('coverLogOut')
      router.push('/login')
    } else {
      return {
        data,
        success: data?.code === '0000'
      }
    }
  }
)

export default request
